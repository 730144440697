import $ from "jquery";

const mediaButtonText = () => {
  const init = () => {
    getText();
  };

  const getText = () => {
    const el = {
      mediaButton: "#offerte_upload_button",
      label: ".upload-btn-desc",
    };

    $(el.mediaButton).on("change", () => {
      console.log($(el.mediaButton).files[0].name);
      const fileName = $(el.mediaButton).value.split("\\").pop();
      $(el.label).textContent = fileName;
    });
  };

  init();
};

try {
  mediaButtonText;
} catch (error) {
  console.log(error);
}
