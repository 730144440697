import $ from "jquery";

const showHidePopup = () => {
  const init = () => {
    showHidePopupVacatures();
  };

  const showHidePopupVacatures = () => {
    $(".js-btn-details").on("click", function (e) {
      e.preventDefault();
      $(".js-popup-vacatures-overlay").addClass("show-popup-overlay");
      $(".js-popup-vacatures").addClass("show-popup");
      var title = $(this).attr("title");
      var time = $(this).attr("data-time");
      var loc = $(this).attr("data-location");
      var job = $(this).attr("data-job");
      var req = $(this).attr("data-req");
      $(".popup-title").text(title);
      $(".popup-time").text(time);
      $(".popup-loc").text(loc);
      $(".popup-job").html(job);
      $(".popup-req").html(req);
    });

    $(".js-popup-vacatures-overlay").on("click", function (e) {
      e.preventDefault();
      $(".js-popup-vacatures-overlay").removeClass("show-popup-overlay");
      $(".js-popup-vacatures").removeClass("show-popup");
    });

    $(".js-popup-vacatures-close").on("click", function (e) {
      e.preventDefault();
      $(".js-popup-vacatures-overlay").removeClass("show-popup-overlay");
      $(".js-popup-vacatures").removeClass("show-popup");
    });
  };

  init();
};

showHidePopup();
